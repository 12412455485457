import React from 'react';
import * as styles from './Testimonials.module.scss';
import classNames from 'classnames';

export function Testimonials({children, className, ...otherProps}) {

    return <div className={classNames(styles.root, className)} {...otherProps}>
        {React.Children.map(children, (child) => {
            return <>{
                React.cloneElement(child, {
                    ...child.props,
                    className: classNames(child.props.className, styles.item)
                })
            }
                <span className={styles.separator}/>
            </>;
        })}
    </div>
}
